// Importing necessary modules from React and ReactDOM
import { createRoot } from 'react-dom/client';

// Importing the main App component
import App from './components/App';

// Getting the DOM node where the app will be attached
const rootElement = document.getElementById('root');

if (!rootElement) {
    throw new Error('Root element not found');
}

const root = createRoot(rootElement);

// Rendering the App component
root.render(<App />);

// Importing the CSS module
import './components/ThemePref/themes.module.css';
