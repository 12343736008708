import React from 'react';

type DataProps = {
  data: any;  // Use your specific type here
};

const DataCard: React.FC<DataProps> = ({ data }) => {
  const cardStyle = {
    border: '1px solid #ccc',
    padding: '20px',
    marginBottom: '20px',
    borderRadius: '5px',
  };

  const headerStyle = {
    marginBottom: '10px',
    borderBottom: '1px solid #ccc',
  };

  const listStyle = {
    listStyleType: 'none',
    paddingLeft: 0,
  };

  return (
    <div style={cardStyle}>
      <div style={headerStyle}>
        <h2>{data.ButikkNavn}</h2>
      </div>
      <p><strong>ButikkID:</strong> {data.ButikkID}</p>
      <p><strong>Dato:</strong> {data.Dato}</p>
      <p><strong>Omsetning Inkl Mva:</strong> {data.OmsetningInklMva}</p>
      <p><strong>Antall Kunder:</strong> {data.AntallKunder}</p>

      <h3>Varer:</h3>
      <ul style={listStyle}>
        {data.Varer.map((item: any, index: number) => (
          <li key={index}>
            <strong>{item.Navn}</strong> - {item.InkMva}
          </li>
        ))}
      </ul>

      {/* Add more fields here */}
    </div>
  );
};

export default DataCard;
