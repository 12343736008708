import React, { useState } from 'react';

const StoreTool: React.FC = () => {
  const [showMessage, setShowMessage] = useState(false);

  const stores = ["Floyd", "Nille", "Kafe kvisten", "spar"]; // Add or remove store names as needed

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}>
      <header style={{ background: '#f5f5f5', padding: '10px', textAlign: 'center' }}>
        <h1>This is StoreTool!</h1>
      </header>
      <main style={{ marginTop: '20px' }}>
        <h1>Aamotsenteret</h1>
        <button 
          onClick={() => setShowMessage(!showMessage)}
          style={{ padding: '10px 20px', marginTop: '20px', cursor: 'pointer' }}
        >
          Stores!
          </button>
        {showMessage && (
          <div style={{ marginTop: '20px' }}>
            {stores.map((store, index) => (
              <p key={index}>{store}</p>
            ))}
          </div>
        )}
      </main>
    </div>
  );
};

export default StoreTool;
