import React, { useState } from 'react';

const DataFetcher: React.FC = () => {
  const [inputValue, setInputValue] = useState('');
  const [data, setData] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(`https://data.brreg.no/enhetsregisteret/api/enheter/${inputValue}`);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  return (
    <div>
      <input type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
      <button onClick={fetchData}>Fetch Data</button>
      {data && <pre>{JSON.stringify(data, null, 2)}</pre>}
    </div>
  );
};

export default DataFetcher;
