import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

// Importing existing and new page components
import HomePage from './HomePage';
import DataDisplayPage from './DataDisplayPage';
import StoreTool from './StoreTool';
import ThemePref from './ThemePref/ThemePref';
import { ThemeProvider } from './ThemePref/ThemeContext'; // Adjust the path if necessary


const App: React.FC = () => {
  const [hoveredLink, setHoveredLink] = useState<string | null>(null);

  const linkStyle = {
    display: 'inline-block',
    padding: '10px 15px',
    margin: '5px',
    backgroundColor: '#808080',
    color: 'white',
    textDecoration: 'none',
    borderRadius: '4px',
    transition: 'background-color 0.3s',
    cursor: 'pointer',
  };

  return (
    <ThemeProvider>
      <Router>
        <div>
          {/* Theme Toggle Button */}
          <ThemePref />

          {/* Navigation Links */}
          <nav>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li>
                <Link
                  to="/"
                  style={{
                    ...linkStyle,
                    backgroundColor: hoveredLink === '/' ? '#808080' : '#C0C0C0',
                  }}
                  onMouseEnter={() => setHoveredLink('/')}
                  onMouseLeave={() => setHoveredLink(null)}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/StoreTool"
                  style={{
                    ...linkStyle,
                    backgroundColor: hoveredLink === '/StoreTool' ? '#808080' : '#C0C0C0',
                  }}
                  onMouseEnter={() => setHoveredLink('/StoreTool')}
                  onMouseLeave={() => setHoveredLink(null)}
                >
                  StoreTool
                </Link>
              </li>
              <li>
                <Link
                  to="/websocket"
                  style={{
                    ...linkStyle,
                    backgroundColor: hoveredLink === '/websocket' ? '#808080' : '#C0C0C0',
                  }}
                  onMouseEnter={() => setHoveredLink('/websocket')}
                  onMouseLeave={() => setHoveredLink(null)}
                >
                  WebSocket Example
                </Link>
              </li>
            </ul>
          </nav>

          {/* Route Definitions */}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/StoreTool" element={<StoreTool />} />
            <Route path="/websocket" element={<DataDisplayPage />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
