import React from 'react';
import DataFetcher from './DataFetcher'; // adjust the import path as needed


const HomePage: React.FC = () => {
  return (
    <div>
      <h1>This is the home page!</h1>
      <DataFetcher />
    </div>
  );
};

export default HomePage;
