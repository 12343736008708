import React, { useEffect, useState } from 'react';
import DataCard from './DataCard';  // Import the DataCard component

const DataDisplayPage: React.FC = () => {
  const [data, setData] = useState<any>(null);  // If you have a specific type for the data, replace 'any'
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch('/api/listener');
        if (!res.ok) {
          const errorText = await res.text();
          throw new Error(`Network response was not ok: ${errorText}`);
        }

        let json;
        try {
          json = await res.json();
        } catch (jsonError) {
          throw new Error('Invalid JSON response');
        }

        if (!json || typeof json !== 'object') {
          throw new Error('Received invalid data');
        }

        setData(json);
        setError(null);  // Clear previous errors if data fetch is successful
      } catch (error) {
        if (error instanceof Error) {
          setError(error);
        } else {
          setError(new Error('An unknown error occurred'));
        }
      }
    };

    // Fetch data initially
    fetchData();

    // Poll every 5 seconds
    const intervalId = setInterval(fetchData, 5000);

    // Cleanup: clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <h1>Received Data</h1>
      {error && <p>Error: {error.message}</p>}
      {data ? <DataCard data={data} /> : 'Loading...'}
    </div>
  );
};

export default DataDisplayPage;
