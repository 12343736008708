// themepref.tsx
import React from 'react';
import { useTheme } from './ThemeContext';
import styles from './themes.module.css';

const ThemePref: React.FC = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <button onClick={toggleTheme} className={styles.button}>
      Switch to {theme === 'light' ? 'dark' : 'light'} theme
    </button>
  );
};

export default ThemePref;
